var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 偏好设置 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入关键字","allowClear":""},model:{value:(_vm.keyWord),callback:function ($$v) {_vm.keyWord=$$v},expression:"keyWord"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v("搜索")]),_c('a-button',{staticClass:"all_boder_btn",on:{"click":_vm.onAdd}},[_vm._v("新建标签")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.likeId; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"pagination":{
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"operation",fn:function(item){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{on:{"click":function($event){return _vm.editData(item.likeId)}}},[_vm._v("编辑")]),_c('span',[_vm._v("|")]),_c('a',{on:{"click":function($event){return _vm.deleteData(item)}}},[_vm._v("删除")])])]}}])})],1),_c('a-modal',{attrs:{"okText":"确认","title":(_vm.state == 1 ? '新建' : '编辑') + '偏好',"width":"360px"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"modal_box"},[_c('div',[_c('span',{staticClass:"tag"},[_vm._v("偏好名称：")]),_c('a-input',{attrs:{"type":"text","placeholder":"请输入偏好名称"},model:{value:(_vm.likeLabelName),callback:function ($$v) {_vm.likeLabelName=$$v},expression:"likeLabelName"}})],1),_c('div',{staticClass:"box_upload"},[_c('span',{staticClass:"tag"},[_vm._v("偏好图片：")]),_c('a-upload',{attrs:{"list-type":"picture-card","showUploadList":false,"accept":".jpg,.png,.jpeg,.JPG,.PNG,.JPEG","beforeUpload":_vm.beforeUpload,"customRequest":_vm.customRequest}},[(_vm.img)?_c('img',{staticStyle:{"max-width":"180px"},attrs:{"src":_vm.img,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }