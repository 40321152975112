<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 偏好设置 </template>
      <template #input>
        <!-- <span class="tag">标签名称：</span> -->
        <a-input placeholder="请输入关键字" v-model="keyWord" allowClear />
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
        <a-button class="all_boder_btn" @click="onAdd">新建标签</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.likeId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="editData(item.likeId)">编辑</a>
            <span>|</span>
            <a @click="deleteData(item)">删除</a>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      okText="确认"
      :title="(state == 1 ? '新建' : '编辑') + '偏好'"
      width="360px"
      @ok="handleOk"
    >
      <div class="modal_box">
        <div>
          <span class="tag">偏好名称：</span>
          <a-input
            type="text"
            v-model="likeLabelName"
            placeholder="请输入偏好名称"
          />
        </div>

        <div class="box_upload">
          <span class="tag">偏好图片：</span>
          <a-upload
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img v-if="img" :src="img" style="max-width: 180px" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "偏好名称",
    align: "center",
    dataIndex: "likeLabelName",
  },
  {
    title: "关注人数",
    align: "center",
    dataIndex: "followNum",
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      img: "", //关联图片
      likeId: "", //标签id
      likeLabelName: "", //新增或编辑标签

      keyWord: "", //标签关键字搜索

      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      PreventLoad: null , // 防抖变量
    };
  },
  // 事件处理器
  methods: {
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 重置文件上传 - 单文件
    customRequest(e) {
      this.$upload({
        file: e.file,
        progress: (e) => {
          this.loading = true;
        },
        success: (e) => {
          // console.log(e);
          this.loading = false;
          this.img = e;
        },
      });
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    // tab切换
    callback(e) {},
    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { id: 1 },
      });
    },
    onAdd() {
      this.state = 1;
      this.likeLabelName = "";
      this.img = "";
      this.visible = true;
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getData();
    },
    // 获取数据
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/like/",
        method: "get",
        params: {
          keyWord: this.keyWord,
        },
      }).then((res) => {
        this.tableData = res.data;
        this.pageNumber = 1;
        this.loading = false;
      });
    },
    // 确定新建
    handleOk() {
      if (!this.likeLabelName) {
        this.$message.warning("请正确填写");
        return;
      }
      if (!this.img) {
        this.$message.warning("请上传图片");
        return;
      }
      let params = {};
      let method = "";
      // 新增
      if (this.state == 1) {
        method = "POST";
        params = {
          likeLabelName: this.likeLabelName,
          img: this.img,
        };
      }
      // 编辑
      else if (this.state == 2) {
        method = "PUT";
        params = {
          likeId: this.likeId,
          likeLabelName: this.likeLabelName,
          img: this.img,
        };
      }
      if (this.PreventLoad) {
        clearTimeout(this.PreventLoad);
      } else {
        this.$ajax({
          url: "/hxclass-management/like/",
          method,
          params,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.visible = false;
            this.likeLabelName = "";
            this.img = "";
            this.getData();
            if (this.state == 1) {
              this.$message.success("新增标签成功");
            } else {
              this.$message.success("编辑标签成功");
            }
          } else {
            this.$message.warning(res.message);
          }
        });
      }
      this.PreventLoad = setTimeout(() => {
        this.PreventLoad = null;
      }, 500);
    },
    // 编辑标签
    editData(id) {
      this.state = 2;
      this.visible = true;
      // 详情
      this.$ajax({
        url: "/hxclass-management/like/manage/details",
        method: "get",
        params: {
          likeId: id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(res.data);
          this.img = res.data.img; //关联图片
          this.likeId = res.data.likeId; //标签id
          this.likeLabelName = res.data.likeLabelName; //新增或编辑标签
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 删除标签
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确认删除该偏好?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/like/?likeId=" + item.likeId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              }
            });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取数据
    this.getData();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.modal_box {
  display: flex;
  flex-direction: column;
  // align-items: center;
  .tag {
    flex-shrink: 0;
  }
  .box_upload {
    margin-top: 10px;
  }
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  // /deep/.ant-table {
  //   border: 1px solid #eeeeee;
  //   box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  // }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
</style>
